import { Component, OnInit } from '@angular/core';

import { WmProgressSpinnerService } from '@mg-services/wm-progress-spinner.service';


@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {

  public mSpinnerColor = 'primary';
  public isVisible$ = this.wmProgressSpinnerService.mVisible$;

  constructor(private wmProgressSpinnerService: WmProgressSpinnerService) { }

  ngOnInit() {
  }

}
