import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dj-events-list',
  templateUrl: './dj-page-events-list.component.html',
  styleUrls: ['./dj-page-events-list.component.scss']
})
export class DjPageEventsListComponent implements OnInit {

  matTableColumns: string[] = ['event_id', 'event_title', 'event_date', 'number_of_music_requests', 'number_of_guests', 'row_selected'];

  currentDj$ = this.wmDataService.currentDj$;

  mEvents$: Observable<WmEvent[]> = this.wmDataService.allAvailableEventsForDj$;

  mEventsWithSelectedRow$ = combineLatest([this.mEvents$, this.wmDataService.selectedDjPageEventRow$]).pipe(
    map(([events, selectedRow]) => (events as any[]).map(
      (oneEvent) => {
        oneEvent.selected = false;
        if (selectedRow > -1) {
          const tSelectedEventId = events[selectedRow].event_id;
          if (oneEvent.event_id === tSelectedEventId) { oneEvent.selected = true; }
        }
        return oneEvent;
      }
    )) // end-map() & end-map()
  );

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
  }

  async rowClicked(pRow) {
    this.wmDataService.setSelectedDjPageEventRow(pRow);
  }

  // end-of-class
}
