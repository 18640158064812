import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';

@Component({
  selector: 'app-host-event-details',
  templateUrl: './host-page-event-details.component.html',
  styleUrls: ['./host-page-event-details.component.scss']
})
export class HostPageEventDetailsComponent implements OnInit {

  detailedEvent$: Observable<WmEvent> = this.wmDataService.selectedHostPageEvent$;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() { }

}

