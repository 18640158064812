import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap, first, take, shareReplay } from 'rxjs/operators';

import { WmUser } from '@mg-data-model/wm-user';

export interface AdminUser {
  isAdmin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  // ----------------------------------------------------------------------------------------------------------------
  public uid$ = this.afAuth.authState.pipe(
    map(authState => {
      if (!authState) {
        return null;
      } else {
        return authState.uid;
      }
    })
  );

  // ----------------------------------------------------------------------------------------------------------------
  public loggedIn$ = this.uid$.pipe(map(uid => !!uid));

  // ----------------------------------------------------------------------------------------------------------------
  public currentWmUser$: Observable<WmUser> = this.uid$.pipe(
    switchMap(uid => {
      if (!uid) { return of(null); }
      return this.afs.collection('/users').doc(uid).valueChanges().pipe(
        map(x => {
          if (!x) { return x; }
          const tWmUser = x as WmUser;
          tWmUser.user_id = uid;
          return x;
        }), // end-map()
      ); // end-pipe()
    }), // end-switchMap()
    shareReplay(1),
  ); // end-pipe()

  // ----------------------------------------------------------------------------------------------------------------
  public isAdmin$ = this.afAuth.authState.pipe(
    switchMap(authState => {
      if (!authState) { return of(false); }
      return authState.getIdTokenResult().then(
        (idTokenResult) => idTokenResult.claims.amgee_admin);
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  // CONSTRUCTOR
  // ----------------------------------------------------------------------------------------------------------------
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  async isLoggedIn(): Promise<boolean> {
    return this.loggedIn$.pipe(take(1)).toPromise();
  }

  // ----------------------------------------------------------------------------------------------------------------
  async isAdmin(): Promise<boolean> {
    return this.isAdmin$.pipe(take(1)).toPromise();
  }

  // ----------------------------------------------------------------------------------------------------------------
  getAuthUser(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  // ----------------------------------------------------------------------------------------------------------------
  async performLogin(pUserEmail: string, pUserPassword: string) {
    try {
      const tResult = await this.afAuth.auth.signInWithEmailAndPassword(pUserEmail, pUserPassword);
      return true;
    } catch (error) {
      console.log('MG: error = ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------
  async performLogout() {
    await this.afAuth.auth.signOut();
  }

  // end-of-class
}
