import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material';


import { WmDataService } from '@mg-services/wm-data.service';
import { WmUser } from '@mg-data-model/wm-user';
import { CommonConfirmationDialogComponent } from '@mg-ui/common/dialogs/common-confirmation-dialog/common-confirmation-dialog.component';


@Component({
  selector: 'app-users-short',
  templateUrl: './admin-page-users-list.component.html',
  styleUrls: ['./admin-page-users-list.component.scss']
})
export class AdminPageUsersListComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  matTableColumns: string[] = ['user_name', 'user_email', 'user_id', 'user_roles', 'row_selected', 'actions'];

  mDataSource: MatTableDataSource<any>;
  mDataSourceSubscription: Subscription;

  mUsers$: Observable<WmUser[]> = this.wmDataService.allWmUsers$;

  mUsersDisplay$ = combineLatest([this.mUsers$, this.wmDataService.selectedAdminPageUserId$]).pipe(
    map(([users, selectedUserId]) => (users as any[]).map(
      (oneUser) => {
        // user row selected ?
        oneUser.selected = false;
        if (oneUser.user_id === selectedUserId) { oneUser.selected = true; }

        // user roles ?
        let tUserRolesDisplay = '';
        if (!oneUser.user_roles) { return oneUser; }
        oneUser.user_roles.forEach(iUserRole => {
          if (iUserRole === 'dj') { tUserRolesDisplay += 'DJ,'; }
          if (iUserRole === 'guest') { tUserRolesDisplay += 'Guest,'; }
          if (iUserRole === 'host') { tUserRolesDisplay += 'Host,'; }
        });
        if (tUserRolesDisplay.length > 0) { tUserRolesDisplay = tUserRolesDisplay.substr(0, tUserRolesDisplay.length - 1); }
        oneUser.user_roles_display = tUserRolesDisplay;

        // return user as object
        return oneUser;
      }
    )) // end-map() & end-map()
  );


  constructor(
    private wmDataService: WmDataService,
    private mConfirmationDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.mDataSourceSubscription = this.mUsersDisplay$.subscribe(data => {
      this.mDataSource = new MatTableDataSource(data);
      this.mDataSource.sort = this.sort;
    });
  }

  ngOnDestroy() {
    this.mDataSourceSubscription.unsubscribe();
  }

  selectUser(pUserId: string) {
    this.wmDataService.setSelectedAdminPageUserId(pUserId);
  }

  async deleteUser(pUserId: string) {
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure to delete this data ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('MG: deleting user with id = ', pUserId);
        const tSuccess = await this.wmDataService.deleteWmUser(pUserId);
        console.log('MG: deleting succesful = ', tSuccess);
      }
    });
  }

  async wreckDeviceIds(pUserId: string) {
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to wreck the device-ids of this user',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('MG: wrecking device-ids for user with id = ', pUserId);
        const tSuccess = await this.wmDataService.wreckDeviceIds(pUserId);
        console.log('MG: wrecking succesful = ', tSuccess);
      }
    });
  }

  trackById(index, item) {
    return item.user_id;
  }

  // end-of-class
}
