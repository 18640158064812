import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-events-short',
  templateUrl: './admin-page-events-list.component.html',
  styleUrls: ['./admin-page-events-list.component.scss']
})
export class AdminPageEventsListComponent implements OnInit, OnDestroy {
  matTableColumns: string[] = ['event_id', 'purchase_status', 'event_title', 'event_date', 'is_done', 'is_live'
    , 'music_requests', 'number_of_guests', 'row_selected'];

  mDataSource: MatTableDataSource<any>;

  mEvents$: Observable<WmEvent[]> = this.wmDataService.allWmEvents$;
  mEventsWithSelectedRow$ = combineLatest([this.mEvents$, this.wmDataService.selectedAdminPageEventId$]).pipe(
    map(([events, selectedEventId]) => (events as any[]).map(
      (oneEvent) => {
        oneEvent.selected = false;
        if (oneEvent.event_id === selectedEventId) { oneEvent.selected = true; }
        return oneEvent;
      }
    )) // end-map() & end-map()
  );

  mEventsSubscription: Subscription;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
    this.mEventsSubscription = this.mEventsWithSelectedRow$.subscribe(data => {
      this.mDataSource = new MatTableDataSource(data);
      this.mDataSource.sort = this.sort;
    });
  }

  ngOnDestroy(): void {
    this.mEventsSubscription.unsubscribe();
  }


  selectEvent(pEventId: string) {
    this.wmDataService.setSelectedAdminPageEventId(pEventId);
  }

  trackById(index, item) {
    return item.event_id;
  }

  // end-of-class
}
