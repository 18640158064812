import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material';


import { WmUser } from '@mg-data-model/wm-user';
import { WmDataService } from '@mg-services/wm-data.service';
import { NotificationService } from '@mg-services/notification.service';
import { CommonConfirmationDialogComponent } from '@mg-ui/common/dialogs/common-confirmation-dialog/common-confirmation-dialog.component';


@Component({
  selector: 'app-admin-auth-user-details',
  templateUrl: './admin-auth-user-details.component.html',
  styleUrls: ['./admin-auth-user-details.component.scss']
})
export class AdminAuthUserDetailsComponent implements OnInit {

  detailedUser$: Observable<WmUser>; // = this.wmDataService.selectedAdminPageUser$;

  constructor(
    private wmDataService: WmDataService,
    private notificationService: NotificationService,
    private mConfirmationDialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  clearSelection() {
    this.wmDataService.setSelectedAdminPageUserId(null);
  }

  async deleteUser() {
    const tUser = await this.detailedUser$.pipe(take(1)).toPromise();
    console.log('MG: deleteUser() ', tUser.user_id);

    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure to delete this data ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('MG: deleting user with id = ', tUser.user_id);
        const tSuccess = await this.wmDataService.deleteWmUser(tUser.user_id);
        console.log('MG: deleting succesful = ', tSuccess);
      }
    });

  }

  // end-of-class
}
