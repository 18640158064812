import { Component, OnInit } from '@angular/core';
import { WmDataService } from '@mg-services/wm-data.service';

@Component({
  selector: 'app-host-event-music-reqs',
  templateUrl: './host-page-music-reqs-list.component.html',
  styleUrls: ['./host-page-music-reqs-list.component.scss']
})
export class HostPageMusicReqsListComponent implements OnInit {

  matTableColumns: string[] = ['music_request', 'count'];

  public allHostEventMusicRequests$ = this.wmDataService.selectedHostPageEventMusicReqs$;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
  }

}
