import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserAuthService } from '@mg-services/user-auth.service';


interface User {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Wedding Music / Web';

  mUser$: Observable<User>;

  constructor(
    public userAuthService: UserAuthService,
    public translateService: TranslateService
  ) {
    translateService.addLangs(['en', 'de']);
    translateService.setDefaultLang('en');

    const browserLanguage = translateService.getBrowserLang();

    if (translateService.getLangs().includes(browserLanguage)) {
      translateService.use(browserLanguage);
    } else {
      translateService.use('en');
    }
  }

  // end-of-class
}
