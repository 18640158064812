import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { WmDataService } from '@mg-services/wm-data.service';

@Component({
  selector: 'app-dj-event-music-reqs',
  templateUrl: './dj-page-music-reqs-list.component.html',
  styleUrls: ['./dj-page-music-reqs-list.component.scss']
})
export class DjPageMusicReqsListComponent implements OnInit, OnDestroy {

  @ViewChild('mgScrollDiv', { static: true }) mMgScrollDiv: ElementRef;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  mDataSource: MatTableDataSource<any>;
  mDataSourceSubscription: Subscription;

  matTableColumns: string[] = ['raw_request_title', 'last_modified_readable', 'count'];

  public selectedMusicReqId$ = new BehaviorSubject<string>(null);

  public mIsLive$ = new BehaviorSubject<boolean>(false);

  public isBold = false;

  public mMusicReqs$ = combineLatest([
    this.mIsLive$,
    this.selectedMusicReqId$,
    this.wmDataService.allDjPageSelectedEventRegularMusicReqs$,
    this.wmDataService.allDjPageSelectedEventLiveMusicReqs$,
  ]).pipe(
    map(([isLive, selectedMusicReqId, allMusicReqs, allLiveMusicReqs]) => {
      this.scrollToTop();
      if (isLive && allLiveMusicReqs) { return allLiveMusicReqs; }
      if (!allMusicReqs) { return null; }
      return allMusicReqs.map(
        oneMusicReq => {
          const tMusicReq = oneMusicReq;
          tMusicReq.selected = (tMusicReq.music_req_id === selectedMusicReqId);
          tMusicReq.last_modified_readable = tMusicReq.last_modified.toDate().toLocaleDateString();
          return tMusicReq;
        }
      );
    }),
  );


  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
    this.mDataSourceSubscription = this.mMusicReqs$.subscribe(data => {
      this.mDataSource = new MatTableDataSource(data);
      this.mDataSource.sort = this.sort;
    });
  }

  ngOnDestroy() {
    this.mDataSourceSubscription.unsubscribe();
  }

  scrollToTop() {
    if (this.mMgScrollDiv) { this.mMgScrollDiv.nativeElement.scrollTop = 0; }
  }

  async selectMusicReq(pMusicReqId: string) {
    const tCurrentLiveStatus = await this.mIsLive$.pipe(take(1)).toPromise();
    if (!tCurrentLiveStatus) {
      this.selectedMusicReqId$.next(pMusicReqId);
    }
  }

  trackById(index, item) {
    return item.music_req_id;
  }

  async toggleLiveStatus() {
    const tCurrentLiveStatus = await this.mIsLive$.pipe(take(1)).toPromise();
    this.mIsLive$.next(!tCurrentLiveStatus);
  }

  // end-of-class
}
