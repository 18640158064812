import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './mg-ui/pages/account/account.component';
import { AdminAuthComponent } from './mg-ui/pages/admin-auth/admin-auth.component';
import { AdminDashboardComponent } from './mg-ui/pages/admin-dashboard/admin-dashboard.component';
import { ContactComponent } from './mg-ui/pages/contact/contact.component';
import { DjDashboardComponent } from './mg-ui/pages/dj-dashboard/dj-dashboard.component';
import { ErrorPageComponent } from './mg-ui/pages/error-page/error-page.component';
import { HomeComponent } from './mg-ui/pages/home/home.component';
import { InfoComponent } from './mg-ui/pages/info/info.component';
import { HostDashboardComponent } from './mg-ui/pages/host-dashboard/host-dashboard.component';
import { PrivacyPolicyComponent } from './mg-ui/pages/privacy-policy/privacy-policy.component';

import { AdminGuard } from './mg-guards/admin.guard';
import { LoggedInUsersGuard } from './mg-guards/logged-in-users.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'account',
    component: AccountComponent,
  },
  {
    path: 'admin-auth',
    component: AdminAuthComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'datenschutz',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'dj-dashboard',
    component: DjDashboardComponent,
    canActivate: [LoggedInUsersGuard],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'host-dashboard',
    component: HostDashboardComponent,
    canActivate: [LoggedInUsersGuard],
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: 'kontakt',
    component: ContactComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },

  // last(!): error-page
  {
    path: '**',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
