import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';

@Component({
  selector: 'app-dj-event-details',
  templateUrl: './dj-page-event-details.component.html',
  styleUrls: ['./dj-page-event-details.component.scss']
})
export class DjPageEventDetailsComponent implements OnInit {

  detailedEvent$: Observable<WmEvent> = this.wmDataService.selectedDjPageEvent$;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() { }

}
