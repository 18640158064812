import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '@mg-services/user-auth.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  constructor(public userAuthService: UserAuthService) {
  }

  ngOnInit() {
  }

}
