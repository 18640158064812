import { Component, OnInit } from '@angular/core';
// import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';

import { UserAuthService } from '@mg-services/user-auth.service';
import { WmDataService } from '@mg-services/wm-data.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SideNavService } from '@mg-services/side-nav.service';

@Component({
  selector: 'app-toolbar-main',
  templateUrl: './toolbar-main.component.html',
  styleUrls: ['./toolbar-main.component.scss']
})
export class ToolbarMainComponent implements OnInit {

  public loggedIn$ = this.userAuthService.loggedIn$;
  public isAdmin$ = this.userAuthService.isAdmin$;
  public isDj$ = this.wmDataService.isDj$;
  public isHost$ = this.wmDataService.isHost$;

  // public accountTranslation$ = this.translateService.get('account').pipe(map(x => x.toUpperCase()));
  // public loginTranslation$ = this.translateService.get('loginButton').pipe(map(x => x.toUpperCase()));
  public adminNavItemTranslation$ = this.translateService.get('admin').pipe(map(x => x.toUpperCase()));
  public adminAuthNavItemTranslation$ = this.translateService.get('admin_auth').pipe(map(x => x.toUpperCase()));
  public djNavItemTranslation$ = this.translateService.get('dj').pipe(map(x => x.toUpperCase()));
  public hostNavItemTranslation$ = this.translateService.get('host').pipe(map(x => x.toUpperCase()));

  public menuItemContactTranslation$ = this.translateService.get('menu_item_contact');
  public menuItemInfoTranslation$ = this.translateService.get('menu_item_info');
  public menuItemPrivacyTranslation$ = this.translateService.get('menu_item_privacy');

  // public isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);

  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private wmDataService: WmDataService,
    private translateService: TranslateService,
    private sideNavService: SideNavService,
    // private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
  }

  navToAccountPage() {
    this.router.navigate(['/account']);
  }

  navToInfoPage() {
    this.router.navigate(['/info']);
  }

  navToContactPage() {
    this.router.navigate(['/contact']);
  }

  navToPrivacyPage() {
    this.router.navigate(['/privacy']);
  }

  toggleSidenav() {
    this.sideNavService.toggle();
  }

  switchToLangauge(pLanguageCode: string) {
    console.log('MG: switchToLangauge = ', pLanguageCode);
    this.translateService.use(pLanguageCode);
  }

  // end-of-class
}
