import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SideNavService } from '@mg-services/side-nav.service';
import { MatSidenav } from '@angular/material';

import { UserAuthService } from '@mg-services/user-auth.service';
import { WmDataService } from '@mg-services/wm-data.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements AfterViewInit {

  @ViewChild('sidenav', { static: false }) public mSidenav: MatSidenav;

  public loggedIn$ = this.userAuthService.loggedIn$;
  public isAdmin$ = this.userAuthService.isAdmin$;
  public isDj$ = this.wmDataService.isDj$;
  public isHost$ = this.wmDataService.isHost$;

  constructor(
    private sideNavService: SideNavService,
    private userAuthService: UserAuthService,
    private wmDataService: WmDataService,
  ) { }

  ngAfterViewInit() {
    this.sideNavService.setSidenav(this.mSidenav);
  }

  closeSidenav() {
    this.sideNavService.close();
  }

}
