import { Component, OnInit } from '@angular/core';
import { VERSION } from '@angular/core';
import { version } from '../../../../../package.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  angularVersionString = VERSION.full;
  appVersionString = version.toString();

  public infoHeaderTranslation$ = this.translateService.get('header_info');

  constructor(private translateService: TranslateService) { }

  ngOnInit() { }

}
