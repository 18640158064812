import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmUser } from '@mg-data-model/wm-user';

@Component({
  selector: 'app-admin-user-select',
  templateUrl: './admin-user-select.component.html',
  styleUrls: ['./admin-user-select.component.scss']
})
export class AdminUserSelectComponent implements OnInit {

  @Input() adminRole: string;

  public isAdmin$ = this.wmDataService.isAdmin$;

  public selectableUsers$: Observable<WmUser[]>;
  public selectedUser$: Observable<WmUser>;
  public userWasSelected$: Observable<boolean>;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
    switch (this.adminRole.toLowerCase()) {
      case 'dj':
        this.selectableUsers$ = this.wmDataService.allDjs$;
        this.selectedUser$ = this.wmDataService.selectedDjByAdmin$;
        this.userWasSelected$ = this.wmDataService.djWasSelectedByAdmin$;
        break;

      case 'host':
        this.selectableUsers$ = this.wmDataService.allHosts$;
        this.selectedUser$ = this.wmDataService.selectedHostByAdmin$;
        this.userWasSelected$ = this.wmDataService.hostWasSelectedByAdmin$;
        break;

      default:
        // DO NOTHING
        break;
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  onSelectionChange(event) {
    if (!this.adminRole) { return; }
    this.wmDataService.selectUserByAdmin(this.adminRole, event.value);
  }

  // ----------------------------------------------------------------------------------------------------------------
  deselectButtonClicked() {
    if (!this.adminRole) { return; }
    this.wmDataService.selectUserByAdmin(this.adminRole, null);
  }

  // end-of-class
}
