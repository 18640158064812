import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Notification, NotificationType } from '@mg-data-model/notification';

@Injectable()
export class NotificationService {

  private mSubject = new Subject<Notification>();
  private mIndex = 0;

  constructor() { }

  getObservable(): Observable<Notification> {
    return this.mSubject.asObservable();
  }

  info(title: string, message: string, timeout = 2500) {
    this.mSubject.next(new Notification(this.mIndex++, NotificationType.info, title, message, timeout));
  }

  success(title: string, message: string, timeout = 2500) {
    this.mSubject.next(new Notification(this.mIndex++, NotificationType.success, title, message, timeout));
  }

  warning(title: string, message: string, timeout = 3000) {
    this.mSubject.next(new Notification(this.mIndex++, NotificationType.warning, title, message, timeout));
  }

  error(title: string, message: string, timeout = 0) {
    this.mSubject.next(new Notification(this.mIndex++, NotificationType.error, title, message, timeout));
  }

}