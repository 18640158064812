import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';
import { CommonEditDialogComponent } from '@mg-ui/common/dialogs/common-edit-dialog/common-edit-dialog.component';
import { CommonConfirmationDialogComponent } from '@mg-ui/common/dialogs/common-confirmation-dialog/common-confirmation-dialog.component';

@Component({
  selector: 'app-event-detail',
  templateUrl: './admin-page-event-details.component.html',
  styleUrls: ['./admin-page-event-details.component.scss']
})
export class AdminPageEventDetailsComponent implements OnInit {

  detailedEvent$: Observable<WmEvent> = this.wmDataService.selectedAdminPageEvent$;

  constructor(
    private wmDataService: WmDataService,
    private mEditDialog: MatDialog,
    private mConfimDialog: MatDialog,
  ) { }

  ngOnInit() { }

  // ----------------------------------------------------------------------------------------------------------------
  showEditDialog(pPayload: any, pPropertyToEdit: string) {
    const dialogRef = this.mEditDialog.open(CommonEditDialogComponent, {
      width: '350px',
      data: { mPayload: pPayload, mPropertyToEdit: pPropertyToEdit }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  showToggleEventLiveDialog(pEvent) {
    const tEventId = pEvent.event_id;
    const tEventIsCurrentlyLive = pEvent.is_live;
    const tAction = (tEventIsCurrentlyLive) ? '"NOT live"' : '"LIVE"';

    const dialogRef = this.mConfimDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Set event ' + tEventId + ' to ' + tAction + ' ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.wmDataService.setEventLiveMode(tEventId, !tEventIsCurrentlyLive);
      }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  showToggleEventDoneDialog(pEvent) {
    const tEventId = pEvent.event_id;
    const tEventIsCurrentlyDone = pEvent.is_done;
    const tAction = (tEventIsCurrentlyDone) ? '"NOT done"' : '"DONE"';

    const dialogRef = this.mConfimDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Set event ' + tEventId + ' to ' + tAction + ' ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.wmDataService.setEventDoneMode(tEventId, !tEventIsCurrentlyDone);
      }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  showToggleEventDeletedDialog(pEvent) {
    const tEventId = pEvent.event_id;
    const tEventIsCurrentlyDeleted = pEvent.deleted;
    const tAction = (tEventIsCurrentlyDeleted) ? '"NOT deleted"' : '"DELETED"';

    const dialogRef = this.mConfimDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Set event ' + tEventId + ' to ' + tAction + ' ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.wmDataService.setEventDeletedMode(tEventId, !tEventIsCurrentlyDeleted);
      }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  showToggleEventDisabledDialog(pEvent) {
    const tEventId = pEvent.event_id;
    const tEventIsCurrentlyDisabled = pEvent.disabled;
    const tAction = (tEventIsCurrentlyDisabled) ? '"NOT disabled"' : '"DISABLED"';

    const dialogRef = this.mConfimDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Set event ' + tEventId + ' to ' + tAction + ' ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.wmDataService.setEventDisabledMode(tEventId, !tEventIsCurrentlyDisabled);
      }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  clearSelection() {
    this.wmDataService.setSelectedAdminPageEventId(null);
  }

}
