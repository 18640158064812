import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dj-dashboard',
  templateUrl: './dj-dashboard.component.html',
  styleUrls: ['./dj-dashboard.component.scss']
})
export class DjDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
