import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MgInfoDialogData {
  content: string;
}

@Component({
  selector: 'app-mg-info-dialog',
  templateUrl: './mg-info-dialog.component.html',
  styleUrls: ['./mg-info-dialog.component.scss']
})
export class MgInfoDialogComponent implements OnInit {

  // contact_dialog_text
  mInfoTextTranslation$ = this.translateService.get('header_info');

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<MgInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mData: MgInfoDialogData,
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
