import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './mg-ui/pages/home/home.component';
import { AdminDashboardComponent } from './mg-ui/pages/admin-dashboard/admin-dashboard.component';
import { HostDashboardComponent } from './mg-ui/pages/host-dashboard/host-dashboard.component';
import { DjDashboardComponent } from './mg-ui/pages/dj-dashboard/dj-dashboard.component';
import { AccountComponent } from './mg-ui/pages/account/account.component';
import { ErrorPageComponent } from './mg-ui/pages/error-page/error-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { environment } from 'src/environments/environment';

import { AdminGuard } from '@mg-guards/admin.guard';
import { UserAuthService } from '@mg-services/user-auth.service';
import { WmDataService } from '@mg-services/wm-data.service';
import { WmProgressSpinnerService } from '@mg-services/wm-progress-spinner.service';
import { NotificationService } from '@mg-services/notification.service';
import { SideNavService } from '@mg-services/side-nav.service';
import { MaterialUxModule } from '@mg-modules/material-ux/material-ux.module';

import { WmHttpInterceptor } from '@mg-interceptors/wm-http-interceptor';

import { AdminPageEventsListComponent } from './mg-ui/section_components/admin-page-events-list/admin-page-events-list.component';
import { AdminPageUsersListComponent } from './mg-ui/section_components/admin-page-users-list/admin-page-users-list.component';
import { AdminPageUserDetailsComponent } from './mg-ui/section_components/admin-page-user-details/admin-page-user-details.component';
import { AdminPageEventDetailsComponent } from './mg-ui/section_components/admin-page-event-details/admin-page-event-details.component';
import { ToolbarMainComponent } from './mg-ui/toolbar-main/toolbar-main.component';
import { NotificationListComponent } from './mg-ui/common/notification/notification.component';
import { DjPageEventsListComponent } from './mg-ui/section_components/dj-page-events-list/dj-page-events-list.component';
import { DjPageEventDetailsComponent } from './mg-ui/section_components/dj-page-event-details/dj-page-event-details.component';
import { DjPageMusicReqsListComponent } from './mg-ui/section_components/dj-page-music-reqs-list/dj-page-music-reqs-list.component';
import { HostPageEventsListComponent } from './mg-ui/section_components/host-page-events-list/host-page-events-list.component';
import { HostPageEventDetailsComponent } from './mg-ui/section_components/host-page-event-details/host-page-event-details.component';
import { HostPageMusicReqsListComponent } from './mg-ui/section_components/host-page-music-reqs-list/host-page-music-reqs-list.component';
import { HostPageGuestsListComponent } from './mg-ui/section_components/host-page-guests-list/host-page-guests-list.component';
import { AdminUserSelectComponent } from './mg-ui/section_components/shared/admin/admin-user-select/admin-user-select.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { InfoComponent } from './mg-ui/pages/info/info.component';
import { ContactComponent } from './mg-ui/pages/contact/contact.component';
import { MgInfoDialogComponent } from './mg-ui/common/dialogs/mg-info-dialog/mg-info-dialog.component';
import { CommonEditDialogComponent } from './mg-ui/common/dialogs/common-edit-dialog/common-edit-dialog.component';
import { CommonConfirmationDialogComponent } from './mg-ui/common/dialogs/common-confirmation-dialog/common-confirmation-dialog.component';
import { AdminAuthComponent } from './mg-ui/pages/admin-auth/admin-auth.component';
import { AdminAuthUsersListComponent } from './mg-ui/section_components/admin-auth-users-list/admin-auth-users-list.component';
import { AdminAuthUserDetailsComponent } from './mg-ui/section_components/admin-auth-user-details/admin-auth-user-details.component';
import { ProgressSpinnerComponent } from './mg-ui/common/progress_indicators/progress-spinner/progress-spinner.component';
import { SideNavComponent } from './mg-ui/side-nav/side-nav.component';
import { PrivacyPolicyComponent } from './mg-ui/pages/privacy-policy/privacy-policy.component';

export function createTranslateHttpLoader(pHttpClient: HttpClient) {
  return new TranslateHttpLoader(pHttpClient);
}

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    AdminDashboardComponent,
    HostDashboardComponent,
    DjDashboardComponent,
    AccountComponent,
    ErrorPageComponent,

    AdminPageEventsListComponent,
    AdminPageUsersListComponent,
    AdminPageUserDetailsComponent,
    AdminPageEventDetailsComponent,
    ToolbarMainComponent,
    NotificationListComponent,
    DjPageEventsListComponent,
    DjPageEventDetailsComponent,
    DjPageMusicReqsListComponent,
    HostPageEventsListComponent,
    HostPageEventDetailsComponent,
    HostPageMusicReqsListComponent,
    HostPageGuestsListComponent,
    AdminUserSelectComponent,
    InfoComponent,
    ContactComponent,
    MgInfoDialogComponent,
    CommonEditDialogComponent,
    AdminAuthComponent,
    AdminAuthUsersListComponent,
    CommonConfirmationDialogComponent,
    AdminAuthUserDetailsComponent,
    ProgressSpinnerComponent,
    SideNavComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialUxModule,
    HttpClientModule,
    FlexLayoutModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateHttpLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    }),
  ],
  entryComponents: [
    MgInfoDialogComponent,
    CommonEditDialogComponent,
    CommonConfirmationDialogComponent,
  ],
  providers: [
    AdminGuard,
    UserAuthService,
    WmDataService,
    NotificationService,
    WmProgressSpinnerService,
    SideNavService,
    { provide: HTTP_INTERCEPTORS, useClass: WmHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
