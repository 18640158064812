import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmEvent } from '@mg-data-model/wm-event';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-host-events-list',
  templateUrl: './host-page-events-list.component.html',
  styleUrls: ['./host-page-events-list.component.scss']
})
export class HostPageEventsListComponent implements OnInit {

  matTableColumns: string[] = ['event_id', 'event_title', 'event_date', 'number_of_music_requests', 'number_of_guests', 'row_selected'];

  mEvents$: Observable<WmEvent[]> = this.wmDataService.allAvailableEventsForHost$;

  mEventsWithSelectedRow$ = combineLatest([this.mEvents$, this.wmDataService.selectedHostPageEventId$]).pipe(
    map(([events, selectedEventId]) => (events as any[]).map(
      (oneEvent) => {
        if (oneEvent.event_id === selectedEventId) { oneEvent.selected = true; }
        return oneEvent;
      }
    )) // end-map() & end-map()
  );

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() {
  }

  selectEvent(pEventId: string) {
    this.wmDataService.setSelectedHostPageEventId(pEventId);
  }


  // end-of-class
}

