import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserAuthService } from '@mg-services/user-auth.service';
import { NotificationService } from '@mg-services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUsersGuard implements CanActivate {

  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private notificationService: NotificationService,
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const tIsLoggedIn: boolean = await this.userAuthService.isLoggedIn();

    if (!tIsLoggedIn) {
      this.notificationService.info('Information', 'Please login first !');
      const tree: UrlTree = this.router.parseUrl('account');
      return tree;
    }

    return tIsLoggedIn;
  }

}
