import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MgInfoDialogComponent } from '../../common/dialogs/mg-info-dialog/mg-info-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactHeaderTranslation$ = this.translateService.get('header_contact');
  public contactDialogTextTranslation$ = this.translateService.get('contact_dialog_text');
  public menuItemPrivacyTranslation$ = this.translateService.get('menu_item_privacy');

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  async showContactDialog() {
    console.log('MG: showContactDialog()');

    const dialogRef = this.dialog.open(MgInfoDialogComponent, {
      width: '250px',
      data: {
        content: await this.contactDialogTextTranslation$.pipe(take(1)).toPromise(),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('MG: result = ', result);
    });
  }


  navToPrivacyPage() {
    this.router.navigate(['/privacy']);
  }

}


