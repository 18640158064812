import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { WmEvent } from '@mg-data-model/wm-event';

export interface CommonEditDialogData {
  mPayload: any;
  mPropertyToEdit: string;
}

@Component({
  selector: 'app-common-edit-dialog',
  templateUrl: './common-edit-dialog.component.html',
  styleUrls: ['./common-edit-dialog.component.scss']
})
export class CommonEditDialogComponent implements OnInit {

  newValue: string;
  oldValue = '';

  constructor(
    private mAfs: AngularFirestore,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CommonEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mData: CommonEditDialogData,
  ) { }

  ngOnInit() {
    this.oldValue = this.mData.mPayload[this.mData.mPropertyToEdit];
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSaveClick() {
    const tPropertyToEdit: string = this.mData.mPropertyToEdit;

    let tCollectionName: string;
    let tDocId: string;
    let tDocInSubcollectionPath: string;
    let tOk = true;
    let tIsNumber = false;

    let tPropertyToEditInDb = tPropertyToEdit;

    switch (tPropertyToEdit) {
      case 'event_title':
        tCollectionName = '/events';
        tDocId = this.mData.mPayload.event_id;
        break;

      case 'guest_pw':
        tCollectionName = '/events';
        tDocId = this.mData.mPayload.event_id;
        tDocInSubcollectionPath = 'pw/guest_pw';
        tPropertyToEditInDb = 'pw';
        break;

      case 'creator_user_id':
        tCollectionName = '/events';
        tDocId = this.mData.mPayload.event_id;
        break;

      case 'number_of_guests':
        tCollectionName = '/events';
        tDocId = this.mData.mPayload.event_id;
        tIsNumber = true;
        break;

      case 'number_of_music_requests':
        tCollectionName = '/events';
        tDocId = this.mData.mPayload.event_id;
        tIsNumber = true;
        break;

      case 'user_name':
        tCollectionName = '/users';
        tDocId = this.mData.mPayload.user_id;
        break;

      case 'user_email':
        tCollectionName = '/users';
        tDocId = this.mData.mPayload.user_id;
        break;

      case 'user_pw':
        tCollectionName = '/users';
        tDocId = this.mData.mPayload.user_id;
        tDocInSubcollectionPath = 'pw/user_pw';
        tPropertyToEditInDb = 'pw';
        break;

      default:
        tOk = false;
        break;
    }

    if (tOk) {
      const tNewValue = tIsNumber ? parseInt(this.newValue, 10) : this.newValue;
      // const tNewValue = this.newValue;

      console.log('MG: tCollectionName = ', tCollectionName);
      console.log('MG: tDocId = ', tDocId);
      console.log('MG: tPropertyToEdit = ', tPropertyToEdit);
      console.log('MG: tNewValue = ', tNewValue);

      let tDocPath = `${tCollectionName}/${tDocId}`;
      if (tDocInSubcollectionPath) { tDocPath += '/' + tDocInSubcollectionPath; }
      console.log('MG: tDocPath = ', tDocPath);
      try {
        await this.mAfs.doc(tDocPath).update({ [tPropertyToEditInDb]: tNewValue });
        this.snackBar.open('OK, updated ... ' + tPropertyToEdit.toUpperCase(), '', { duration: 2000 });
      } catch (error) {
        console.log('ERROR = ', error);
      }
    } // end-if

    this.dialogRef.close();
  }

  // end-of-class
}
