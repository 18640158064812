import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavService implements OnDestroy {
  private mSidenav: MatSidenav;
  private mRouterSubscription: Subscription;

  constructor(private mRouter: Router) {
    this.mRouterSubscription = mRouter.events.subscribe(pEvent => {
      if (pEvent instanceof NavigationStart) { this.close(); }
    });
  }

  ngOnDestroy() {
    this.mRouterSubscription.unsubscribe();
  };

  public setSidenav(pSidenav: MatSidenav) {
    this.mSidenav = pSidenav;
  }

  public open(): Promise<MatDrawerToggleResult> {
    return this.mSidenav.open();
  }

  public close(): Promise<MatDrawerToggleResult> {
    return this.mSidenav.close();
  }

  public toggle(): void {
    if (this.mSidenav) { this.mSidenav.toggle(); }
    console.log('MG: SideNavService.toggle()');
  }

  // end-of-class
}
