import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { WmDataService } from '@mg-services/wm-data.service';
import { WmGuest } from '@mg-data-model/wm-guest';

@Component({
  selector: 'app-host-event-guest-list',
  templateUrl: './host-page-guests-list.component.html',
  styleUrls: ['./host-page-guests-list.component.scss']
})
export class HostPageGuestsListComponent implements OnInit {

  matTableColumns: string[] = ['user_name', 'number_of_music_reqs', 'row_selected'];

  mGuests$: Observable<WmGuest[]> = this.wmDataService.selectedHostPageEventGuests$;

  constructor(private wmDataService: WmDataService) { }

  ngOnInit() { }

  async rowClicked(pRow: number) {
    this.wmDataService.setSelectedHostPageUserRow(pRow);
  }

  // end-of-class
}
