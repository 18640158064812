import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, shareReplay, take } from 'rxjs/operators';

import { UserAuthService } from './user-auth.service';
import { WmEvent } from '@mg-data-model/wm-event';
import { WmEventPurchaseInfo } from '@mg-data-model/wm-event-purchase-info';
import { WmEventPw } from '@mg-data-model/wm-event-pw';
import { WmGuest } from '@mg-data-model/wm-guest';
import { WmUser } from '@mg-data-model/wm-user';


@Injectable({
  providedIn: 'root'
})
export class WmDataService {

  // ----------------------------------------------------------------------------------------------------------------
  // constructor
  // ----------------------------------------------------------------------------------------------------------------
  constructor(
    private mAfs: AngularFirestore,
    private userAuthService: UserAuthService,
    private mAfFunc: AngularFireFunctions,
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  // Firestore Reference(s)
  // ----------------------------------------------------------------------------------------------------------------
  readonly WmUsersColl: AngularFirestoreCollection = this.mAfs.collection('/users');
  readonly WmEventsColl: AngularFirestoreCollection = this.mAfs.collection('/events');

  // ----------------------------------------------------------------------------------------------------------------
  // observable(s)
  // ----------------------------------------------------------------------------------------------------------------

  public isAdmin$ = this.userAuthService.isAdmin$;

  public currentWmUser$ = this.userAuthService.currentWmUser$;

  // ----------------------------------------------------------------------------------------------------------------
  public isDj$: Observable<boolean> = this.currentWmUser$.pipe(
    map(currentUser => {
      if (!currentUser) { return false; }
      return currentUser.user_roles.includes('dj');
    }) // end-switchmap()
  );

  public isHost$: Observable<boolean> = this.currentWmUser$.pipe(
    map(currentUser => {
      if (!currentUser) { return false; }
      return currentUser.user_roles.includes('host');
    }) // end-switchmap()
  );

  // ----------------------------------------------------------------------------------------------------------------
  public allWmUsers$ = this.WmUsersColl.valueChanges({ idField: 'user_id' }).pipe(
    switchMap(allUsers => {
      const tArrayOfObservables = allUsers.map(oneUser => {
        const tOneUserRef = this.mAfs.collection('/users').doc(oneUser.user_id);
        const tLastActivityRef = tOneUserRef.collection('last_user_activity').doc('last_user_activity');

        return tLastActivityRef.valueChanges().pipe(
          map(lastUserActivityDoc => {
            // last user activity
            if (lastUserActivityDoc) {
              const tLastUserActivity = (lastUserActivityDoc as any).last_user_activity;
              oneUser.last_user_activity = tLastUserActivity;
            }

            if (oneUser.last_user_activity) {
              const tDate = oneUser.last_user_activity.toDate();
              oneUser.last_user_activity_readable_short = tDate.toLocaleDateString();
              oneUser.last_user_activity_readable_long = tDate.toLocaleDateString() + ' - ' + tDate.toLocaleTimeString();
            }

            if (oneUser.created) {
              const tDate = oneUser.created.toDate();
              oneUser.created_date_readable_short = tDate.toLocaleDateString();
              oneUser.created_date_readable_long = tDate.toLocaleDateString() + ' - ' + tDate.toLocaleTimeString();
            }

            return oneUser;
          }) // end-map()
        ); // end-pipe()
      }); // end-map()

      return combineLatest(tArrayOfObservables);
    }), // end-switchMap

    shareReplay(1)
  ) as Observable<WmUser[]>;


  // ----------------------------------------------------------------------------------------------------------------
  private allWmEventsRaw$ = this.WmEventsColl.valueChanges({ idField: 'event_id' }) as Observable<WmEvent[]>;

  public allWmEvents$: Observable<WmEvent[]> = combineLatest([this.allWmEventsRaw$, this.allWmUsers$]).pipe(
    map(([allEvents, allUsers]) => {
      return allEvents.map(oneEvent => {

        // get "creator name"
        const tFoundCreator = allUsers.find(oneUser => {
          return oneUser.user_id === oneEvent.creator_user_id;
        });
        if (tFoundCreator) {
          oneEvent.creator_name = tFoundCreator.user_name;
        } else {
          oneEvent.creator_name = '-';
        }

        // convert timestamp(s) to readable date(s)
        if (oneEvent.event_date) {
          const tDate = oneEvent.event_date.toDate();
          oneEvent.event_date_readable_short = tDate.toLocaleDateString();
          oneEvent.event_date_readable_long = tDate.toLocaleDateString() + ' - ' + tDate.toLocaleTimeString();
        }
        if (oneEvent.created) {
          const tDate = oneEvent.created.toDate();
          oneEvent.created_date_readable_short = tDate.toLocaleDateString();
          oneEvent.created_date_readable_long = tDate.toLocaleDateString() + ' - ' + tDate.toLocaleTimeString();
        }
        if (oneEvent.last_change) {
          const tDate = oneEvent.last_change.toDate();
          oneEvent.last_change_readable_short = tDate.toLocaleDateString();
          oneEvent.last_change_readable_long = tDate.toLocaleDateString() + ' - ' + tDate.toLocaleTimeString();
        }

        // return altered "oneEvent" object
        return oneEvent;
      }); // end-map()
    }),
    switchMap(allEvents => {
      const tArrayOfObservables = allEvents.map(oneEvent => {
        return this.mAfs.collection('/events').doc(oneEvent.event_id)
          .collection('event_purchase_info').doc('event_purchase_info')
          .valueChanges().pipe(
            map(document => {
              if (document) {
                oneEvent.purchase_info = document as WmEventPurchaseInfo;
              }
              return oneEvent;
            }) // end-map()
          ); // end-pipe()
      }); // end-map()

      return combineLatest(tArrayOfObservables);

    }), // end-switchMap
    shareReplay(1),
  ) as Observable<WmEvent[]>;

  // ----------------------------------------------------------------------------------------------------------------
  public allDjs$ = this.allWmUsers$.pipe(
    map(allUsers => allUsers.filter(user => {
      if (!user.user_roles) { return false; }
      return user.user_roles.includes('dj');
    })),
    shareReplay(1),
  );

  public allHosts$ = this.allWmUsers$.pipe(
    map(allUsers => allUsers.filter(user => {
      if (!user.user_roles) { return false; }
      return user.user_roles.includes('host');
    })),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public selectedDjUserIdByAdmin$ = new BehaviorSubject<string>(null);

  public selectedDjByAdmin$: Observable<WmUser> = combineLatest([this.allDjs$, this.selectedDjUserIdByAdmin$]).pipe(
    map(([allDjs, selectedUserId]) => {
      if (!allDjs || !selectedUserId) { return null; }
      return allDjs.find(user => user.user_id === selectedUserId);
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public selectedHostUserIdByAdmin$ = new BehaviorSubject<string>(null);

  public selectedHostByAdmin$: Observable<WmUser> = combineLatest([this.allHosts$, this.selectedHostUserIdByAdmin$]).pipe(
    map(([allHosts, selectedUserId]) => {
      if (!allHosts || !selectedUserId) { return null; }
      return allHosts.find(user => user.user_id === selectedUserId);
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public currentDj$ = combineLatest([this.currentWmUser$, this.selectedDjByAdmin$]).pipe(
    map(([currentUser, selectedDjByAdmin]) => {
      if (!selectedDjByAdmin) { return currentUser; }
      return selectedDjByAdmin;
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public currentHost$ = combineLatest([this.currentWmUser$, this.selectedHostByAdmin$]).pipe(
    map(([currentUser, selectedHostByAdmin]) => {
      if (!selectedHostByAdmin) { return currentUser; }
      return selectedHostByAdmin;
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public djWasSelectedByAdmin$: Observable<boolean> = combineLatest([
    this.currentDj$,
    this.currentWmUser$
  ]).pipe(
    map(([currentDj, currentWmUser]) => {
      if (!currentDj || !currentWmUser) { return false; }
      return currentDj.user_id !== currentWmUser.user_id;
    }),
    shareReplay(1),
  );

  // ----------------------------------------------------------------------------------------------------------------
  public hostWasSelectedByAdmin$: Observable<boolean> = combineLatest([
    this.currentHost$,
    this.currentWmUser$
  ]).pipe(
    map(([currentHost, currentWmUser]) => {
      if (!currentHost || !currentWmUser) { return false; }
      return currentHost.user_id !== currentWmUser.user_id;
    }),
    shareReplay(1),
  );


  // ----------------------------------------------------------------------------------------------------------------
  public allAvailableEventsForDj$ = combineLatest([this.currentDj$, this.allWmEvents$]).pipe(
    map(([currentDj, allEvents]) => {
      if (!currentDj || !allEvents) { return []; }
      return allEvents.filter(event => {
        if (!event.dj) { return false; }
        return (event.dj as any).dj_user_id === currentDj.user_id;
      });
    }),
    shareReplay(1),
  );

  public allAvailableEventsForHost$ = combineLatest([this.currentHost$, this.allWmEvents$]).pipe(
    map(([currentHost, allEvents]) => {
      if (!currentHost || !allEvents) { return []; }
      return allEvents.filter(event => {
        if (!event.event_hosts) { return false; }
        return event.event_hosts.includes(currentHost.user_id);
      });
    }),
    shareReplay(1),
  );


  // ----------------------------------------------------------------------------------------------------------------
  public selectedAdminPageUserId$ = new BehaviorSubject<string>(null);

  public selectedAdminPageUserPw$ = this.selectedAdminPageUserId$.pipe(
    switchMap(userId => {
      if (!userId) { return of(null); }
      return this.mAfs.collection('/users').doc(userId)
        .collection('pw').doc('user_pw')
        .valueChanges().pipe(
          map((userPwDoc) => {
            if (!userPwDoc) { return ''; }
            return (userPwDoc as WmEventPw).pw;
          }),
        );
    }), // end-switchMap()
  );

  public selectedAdminPageUser$ = combineLatest([
    this.selectedAdminPageUserId$,
    this.allWmUsers$,
    this.selectedAdminPageUserPw$
  ]).pipe(
    map(([selectedUserId, allUsers, userPw]) => {
      const tFoundUser = allUsers.find(oneUser => oneUser.user_id === selectedUserId);
      if (tFoundUser) { tFoundUser.user_pw = userPw; }
      return tFoundUser;
    })
  );

  // ----------------------------------------------------------------------------------------------------------------

  public selectedAdminPageEventId$ = new BehaviorSubject<string>(null);

  public selectedAdminPageEventGuestPw$ = this.selectedAdminPageEventId$.pipe(
    switchMap(eventId => {
      if (!eventId) { return of(null); }
      return this.mAfs.collection('/events').doc(eventId)
        .collection('pw').doc('guest_pw')
        .valueChanges().pipe(
          map((guestPwDoc) => {
            if (!guestPwDoc) { return ''; }
            return (guestPwDoc as WmEventPw).pw;
          }),
        );
    }), // end-switchMap()
  );

  public selectedAdminPageEventDj$ = this.selectedAdminPageEventId$.pipe(
    switchMap(eventId => {
      if (!eventId) { return of(null); }
      return this.mAfs.collection('/events').doc(eventId)
        .collection('dj')
        .snapshotChanges().pipe(
          map((snapshot => {
            if (snapshot.length > 0) {
              return snapshot[0].payload.doc.data(); // return first dj
            } // end-if
            return null;
          }))
        );
    }), // end-switchMap()
  );

  public selectedAdminPageEvent$ = combineLatest([
    this.selectedAdminPageEventId$,
    this.allWmEvents$,
    this.selectedAdminPageEventGuestPw$,
    this.selectedAdminPageEventDj$,
  ]).pipe(
    map(([selectedEventId, allEvents, eventGuestPw, eventDj]) => {
      const tFoundEvent = allEvents.find(oneEvent => oneEvent.event_id === selectedEventId);
      if (tFoundEvent) {
        tFoundEvent.guest_pw = eventGuestPw;
        tFoundEvent.dj = eventDj;
      }
      return tFoundEvent;
    })
  );

  // ----------------------------------------------------------------------------------------------------------------
  public selectedDjPageEventRow$ = new BehaviorSubject<number>(-1);
  public selectedDjPageEvent$ = combineLatest([this.selectedDjPageEventRow$, this.allAvailableEventsForDj$]).pipe(
    switchMap(([row, events]) => {
      if (!events || row < 0) { return of(null); }
      return of(events[row]);
    }),
  );

  public allDjPageSelectedEventRegularMusicReqs$ = this.selectedDjPageEvent$.pipe(
    switchMap(event => {
      if (!event) { return of(null); }
      const tEventId = event.event_id;
      return this.mAfs.collection('/events').doc(tEventId)
        .collection('all_regular_music_requests', ref => ref.where('count', '>', 0))
        .valueChanges({ idField: 'music_req_id' });
    }), // end-switchMap()
  );

  public allDjPageSelectedEventLiveMusicReqs$ = this.selectedDjPageEvent$.pipe(
    switchMap(event => {
      if (!event) { return of(null); }
      const tEventId = event.event_id;
      return this.mAfs.collection('/events').doc(tEventId)
        .collection('live_music_requests', ref => ref.orderBy('timestamp', 'desc'))
        .valueChanges({ idField: 'music_req_id' });
    }), // end-switchMap()
  );

  // ----------------------------------------------------------------------------------------------------------------
  public selectedHostPageEventId$ = new BehaviorSubject<string>(null);

  public selectedHostPageEventGuestPw$ = this.selectedHostPageEventId$.pipe(
    switchMap(eventId => {
      if (!eventId) { return of(null); }
      return this.mAfs.collection('/events').doc(eventId)
        .collection('pw').doc('guest_pw')
        .valueChanges().pipe(
          map((guestPwDoc) => {
            if (!guestPwDoc) { return ''; }
            return (guestPwDoc as WmEventPw).pw;
          }),
        );
    }), // end-switchMap()
  );

  public selectedHostPageEvent$ = combineLatest([
    this.selectedHostPageEventId$,
    this.allAvailableEventsForHost$,
    this.selectedHostPageEventGuestPw$,
  ]).pipe(
    map(([selectedEventId, allEvents, eventGuestPw]) => {
      const tFoundEvent = allEvents.find(oneEvent => oneEvent.event_id === selectedEventId);
      if (tFoundEvent) { tFoundEvent.guest_pw = eventGuestPw; }
      return tFoundEvent;
    })
  );

  // ----------------------------------------------------------------------------------------------------------------

  public selectedHostPageEventMusicReqs$ = this.selectedHostPageEvent$.pipe(
    switchMap(event => {
      if (!event) { return of(null); }
      const tEventId = event.event_id;
      return this.mAfs.collection('/events').doc(tEventId)
        .collection('all_music_requests', ref => ref.where('count', '>', 0))
        .valueChanges();
    }), // end-switchMap()
  );

  public selectedHostPageEventGuests$ = this.selectedHostPageEvent$.pipe(
    switchMap(event => {
      if (!event) { return of(null); }
      const tEventId: string = event.event_id;
      const tAllGuests = this.mAfs.collection('/events').doc(tEventId).collection('all_guests').valueChanges();
      return tAllGuests.pipe(map(allGuests => allGuests.map(oneGuest => {
        const tWmGuest = oneGuest as WmGuest;
        const tMusicReqs = tWmGuest.music_requests.filter(x => x.trim().length > 0);
        tWmGuest.number_of_music_reqs = tMusicReqs.length;
        return tWmGuest;
      })));
    }) // end-switchMap()
  );

  // ----------------------------------------------------------------------------------------------------------------
  public selectedHostPageUserRow$ = new BehaviorSubject<number>(-1);
  public selectedHostPageUser$ = combineLatest([this.selectedHostPageUserRow$, this.selectedHostPageEventGuests$]).pipe(
    switchMap(([row, guests]) => {
      if (!guests || row < 0) { return of(null); }
      return of(guests[row]);
    }),
  );

  // ----------------------------------------------------------------------------------------------------------------
  // public method(s)
  // ----------------------------------------------------------------------------------------------------------------

  public setSelectedAdminPageUserId(pUserId: string) {
    this.selectedAdminPageUserId$.next(pUserId);
  }

  // ----------------------------------------------------------------------------------------------------------------
  public setSelectedAdminPageEventId(pEventId: string) {
    this.selectedAdminPageEventId$.next(pEventId);
  }

  // ----------------------------------------------------------------------------------------------------------------
  public setSelectedDjPageEventRow(rowIndex: number) {
    this.selectedDjPageEventRow$.next(rowIndex);
  }

  // ----------------------------------------------------------------------------------------------------------------
  public setSelectedHostPageEventId(pEventId: string) {
    this.selectedHostPageEventId$.next(pEventId);
  }

  // ----------------------------------------------------------------------------------------------------------------
  public setSelectedHostPageUserRow(rowIndex: number) {
    this.selectedHostPageUserRow$.next(rowIndex);
  }

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  public async selectUserByAdmin(pAdminRole: string, pUserId: string) {
    if (!pAdminRole) { return; }
    switch (pAdminRole.toLowerCase()) {
      case 'dj':
        this.selectedDjUserIdByAdmin$.next(pUserId);
        break;

      case 'host':
        this.selectedHostUserIdByAdmin$.next(pUserId);
        break;

      default:
        break;
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  public async setEventLiveMode(pEventId: string, pNewValue: boolean): Promise<boolean> {
    if (!pEventId || pEventId.length === 0) { return false; }
    try {
      await this.mAfs.collection('/events').doc(pEventId).update({ is_live: pNewValue });

      return true;
    } catch (error) {
      console.log('MG: [WmDataService.setEventLiveMode()] ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------
  public async setEventDoneMode(pEventId: string, pNewValue: boolean): Promise<boolean> {
    if (!pEventId || pEventId.length === 0) { return false; }
    try {
      await this.mAfs.collection('/events').doc(pEventId).update({ is_done: pNewValue });

      if (pNewValue) {
        await this.mAfs.collection('/events').doc(pEventId).update({ is_live: false });
      } // end-if

      return true;
    } catch (error) {
      console.log('MG: [WmDataService.setEventDoneMode()] ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------
  public async setEventDeletedMode(pEventId: string, pNewValue: boolean): Promise<boolean> {
    if (!pEventId || pEventId.length === 0) { return false; }
    try {
      await this.mAfs.collection('/events').doc(pEventId).update({ deleted: pNewValue });

      return true;
    } catch (error) {
      console.log('MG: [WmDataService.setEventDeletedMode()] ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------
  public async setEventDisabledMode(pEventId: string, pNewValue: boolean): Promise<boolean> {
    if (!pEventId || pEventId.length === 0) { return false; }
    try {
      await this.mAfs.collection('/events').doc(pEventId).update({ disabled: pNewValue });

      return true;
    } catch (error) {
      console.log('MG: [WmDataService.setEventDisabledMode()] ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------

  public async deleteWmUser(pWmUserId: string): Promise<boolean> {
    if (!pWmUserId || pWmUserId.length === 0) { return false; }
    try {
      await this.mAfs.collection('/users').doc(pWmUserId).delete();
      return true;
    } catch (error) {
      console.log('MG: [WmDataService.deleteWmUser()] ', error);
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------------------------------

  public async wreckDeviceIds(pWmUserId: string): Promise<boolean> {
    console.log('MG: WmDataService.wreckDeviceIds() called.');
    if (!pWmUserId || pWmUserId.length === 0) { return false; }
    try {
      const tAdminWreckDeviceIdsCallable = this.mAfFunc.httpsCallable('adminWreckDeviceIds');
      const tObservable = tAdminWreckDeviceIdsCallable({ user_id: pWmUserId });
      const tResponse = await tObservable.pipe(take(1)).toPromise();

      console.log('MG: [WmDataService.wreckDeviceIds()] tResponse = ', tResponse);
      return true;
    } catch (error) {
      console.log('MG: [WmDataService.wreckDeviceIds()] ', error);
    }
    return false;
  }


} // end-of-class
