import { Timestamp } from '@firebase/firestore-types';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, Subscription } from 'rxjs';
import { map, take, finalize } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material';


import { CommonConfirmationDialogComponent } from '@mg-ui/common/dialogs/common-confirmation-dialog/common-confirmation-dialog.component';
import { WmProgressSpinnerService } from '@mg-services/wm-progress-spinner.service';

export interface AmgeeAuthUser {
  disabled: boolean;
  display_name: string;
  email: string;
  email_verified?: boolean;
  uid: string;
  last_sign_in: string;
  //
  last_sign_in_as_timestamp: Timestamp;
  //
  amgee_admin: boolean;
}

@Component({
  selector: 'app-admin-auth-users-list',
  templateUrl: './admin-auth-users-list.component.html',
  styleUrls: ['./admin-auth-users-list.component.scss']
})
export class AdminAuthUsersListComponent implements OnInit, OnDestroy {
  matTableColumns: string[] =
    ['uid', 'amgee_user_exists_in_db', 'display_name', 'email', 'email_verified', 'disabled', 'last_sign_in', 'amgee_admin', 'actions'];

  mDataSource: MatTableDataSource<any>;

  private mFetchAllAuthUsersCallable: (data: any) => Observable<any>;
  private mAllAuthUsers$: Observable<AmgeeAuthUser[]>;
  private mallAuthUsersSubscription: Subscription;

  private mDeleteAllNonDbAuthUsersCallable: (data: any) => Observable<any>;
  private mDeleteAuthUserCallable: (data: any) => Observable<any>;
  private mDisableAuthUserCallable: (data: any) => Observable<any>;
  private mEnableAuthUserCallable: (data: any) => Observable<any>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // ----------------------------------------------------------------------------------------------------------------
  constructor(
    // private mAfAuth: AngularFireAuth,
    private mAfFunc: AngularFireFunctions,
    private mConfirmationDialog: MatDialog,
    private mWmProgressSpinnerService: WmProgressSpinnerService,
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  ngOnInit() {
    this.mFetchAllAuthUsersCallable = this.mAfFunc.httpsCallable('adminFetchAllAuthUsers');
    this.mDeleteAllNonDbAuthUsersCallable = this.mAfFunc.httpsCallable('adminDeleteAllNonDbAuthUsers');
    this.mDeleteAuthUserCallable = this.mAfFunc.httpsCallable('adminDeleteAuthUser');
    this.mDisableAuthUserCallable = this.mAfFunc.httpsCallable('adminDisableAuthUser');
    this.mEnableAuthUserCallable = this.mAfFunc.httpsCallable('adminEnableAuthUser');
    this.initAuthUserSubscription();
  }

  // ----------------------------------------------------------------------------------------------------------------
  ngOnDestroy() {
    this.mallAuthUsersSubscription.unsubscribe();
  }

  // ----------------------------------------------------------------------------------------------------------------
  private initAuthUserSubscription() {
    this.mWmProgressSpinnerService.show();
    if (this.mallAuthUsersSubscription) { this.mallAuthUsersSubscription.unsubscribe(); }

    this.mAllAuthUsers$ = this.mFetchAllAuthUsersCallable({ dummy: 'dummy' })
      .pipe(
        map(result => (result as any).amgee_auth_users),
        map(users => users.map(user => {
          if (user.last_sign_in_as_timestamp) {
            const tLastSignInSeconds = user.last_sign_in_as_timestamp._seconds;
            const tDate = new Date(tLastSignInSeconds * 1000);
            user.last_sign_in = tDate.toLocaleDateString();
          } // end-if
          return user;
        })),
        finalize(() => {
          this.mWmProgressSpinnerService.hide();
        }),
      );

    this.mallAuthUsersSubscription = this.mAllAuthUsers$.subscribe(data => {
      this.mDataSource = new MatTableDataSource(data);
      this.mDataSource.sort = this.sort;
    });
  }

  // ----------------------------------------------------------------------------------------------------------------
  refresh() {
    this.initAuthUserSubscription();
  }

  // ----------------------------------------------------------------------------------------------------------------
  selectAuthUser(pAuthUserId: string) {
    console.log('MG: selectAuthUser() pAuthUserId = ', pAuthUserId);
  }


  // ----------------------------------------------------------------------------------------------------------------
  async deleteAuthUser(pAuthUserId: string) {
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure to DELETE this user ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.doDeleteAuthUser(pAuthUserId);
      }
    });
  }

  private async doDeleteAuthUser(pAuthUserId: string) {
    const tObservable = this.mDeleteAuthUserCallable({ auth_user_id: pAuthUserId });
    const tResponse = await tObservable.pipe(take(1)).toPromise();
    this.initAuthUserSubscription();
    return true;
  }

  // ----------------------------------------------------------------------------------------------------------------
  async deleteAllNonDbAuthUsers() {
    // this function should delete ALL AUTH USERS WHO DO NOT EXIST IN DB !
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to DELETE ALL users, WHO do not exist in DB ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.doDeleteAllNonDbAuthUsers();
        console.log('MG: doDeleteAllNonDbAuthUsers() called');
      }
    });
  }

  private async doDeleteAllNonDbAuthUsers() {
    // this function should delete ALL AUTH USERS WHO DO NOT EXIST IN DB !
    const tObservable = this.mDeleteAllNonDbAuthUsersCallable({ dummy: 'dummy' });
    const tResponse = await tObservable.pipe(take(1)).toPromise();
    this.initAuthUserSubscription();
    return true;
  }

  // ----------------------------------------------------------------------------------------------------------------
  async disableAuthUser(pAuthUserId: string) {
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure to DISABLE this user ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.doDisableAuthUser(pAuthUserId);
      }
    });
  }

  private async doDisableAuthUser(pAuthUserId: string) {
    const tObservable = this.mDisableAuthUserCallable({ auth_user_id: pAuthUserId });
    const tResponse = await tObservable.pipe(take(1)).toPromise();
    this.initAuthUserSubscription();
    return true;
  }

  // ----------------------------------------------------------------------------------------------------------------
  async enableAuthUser(pAuthUserId: string) {
    const dialogRef = this.mConfirmationDialog.open(CommonConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure to ENABLE this user ?',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const tSuccess = await this.doEnableAuthUser(pAuthUserId);
      }
    });
  }

  private async doEnableAuthUser(pAuthUserId: string) {
    const tObservable = this.mEnableAuthUserCallable({ auth_user_id: pAuthUserId });
    const tResponse = await tObservable.pipe(take(1)).toPromise();
    this.initAuthUserSubscription();
    return true;
  }

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  trackById(index, item) {
    return item.event_id;
  }

  // end-of-class
}
