import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WmProgressSpinnerService {

  public mInProgress$ = new BehaviorSubject<boolean>(false);
  public mVisible$ = this.mInProgress$.pipe(debounceTime(100));

  constructor() { }

  public show() {
    this.mInProgress$.next(true);
  }

  public hide() {
    this.mInProgress$.next(false);
  }

}
