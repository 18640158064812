import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { WmProgressSpinnerService } from '@mg-services/wm-progress-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class WmHttpInterceptor implements HttpInterceptor {

  constructor(private wmProgressSpinnerService: WmProgressSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.wmProgressSpinnerService.show();
    return next.handle(req).pipe(
      finalize(async () => {
        this.wmProgressSpinnerService.hide();
      }),
    );
  }

}
