import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserAuthService } from '@mg-services/user-auth.service';
import { NotificationService } from '@mg-services/notification.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {


  // ----------------------------------------------------------------------------------------------------------------

  // Translation(s)
  public browserLanguage: string;
  public emailFieldTranslation$ = this.translateService.get('emailField');
  public loginButtonTranslation$ = this.translateService.get('loginButton');
  public passwordFieldTranslation$ = this.translateService.get('passwordField');
  public showPasswordButtonTranslation$ = this.translateService.get('showPaddwordButton');
  public userAccountHeaderTranslation$ = this.translateService.get('user_account_header');

  // form
  public mForm: FormGroup;
  public mPasswordVisible = false;

  // ----------------------------------------------------------------------------------------------------------------
  // CONSTRUCTOR
  constructor(
    public userAuthService: UserAuthService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  ngOnInit() {
    this.mForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });
    this.browserLanguage = this.translateService.getBrowserLang();
  }

  // ----------------------------------------------------------------------------------------------------------------
  async loginButtonClicked() {
    if (this.mForm.valid) {
      const tUserEmail = this.mForm.controls.email.value;
      const tUserPassword = this.mForm.controls.password.value;
      const tSuccess = await this.userAuthService.performLogin(tUserEmail, tUserPassword);
      if (tSuccess) {
        this.snackBar.open('You\'re now logged in !', 'OK', { duration: 2000 });
      } else {
        this.snackBar.open('Sorry, wrong password or user does not exist !', 'OK', { duration: 2000 });
      } // end-if
    } else {
      // this.notificationService.warning('Warning', 'Please make sure that your login data is VALID !');
      this.snackBar.open('Please make sure that your login data is VALID !', 'OK', { duration: 2000 });
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  async logoutButtonClicked() {
    await this.userAuthService.performLogout();
    this.snackBar.open('You\'re now logged out !', 'OK', { duration: 2000 });
  }

  async togglePasswordVisibility() {
    console.log('MG: togglePasswordVisibility()');
    this.mPasswordVisible = !this.mPasswordVisible;
  }


  // end-of-class
}
