import { NgModule } from '@angular/core';

import { MatInputModule, MatSelectModule, MatButtonModule, MatCheckboxModule, MatChipsModule } from '@angular/material';
import { MatTableModule, MatToolbarModule, MatIconModule, MatMenuModule, MatSortModule } from '@angular/material';
import { MatSnackBarModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule, MatListModule } from '@angular/material';


const AllMaterialModules = [
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTableModule,
  MatToolbarModule,
  MatIconModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSortModule,
  MatSnackBarModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatListModule,
];

// const appearanceOptions: MatFormFieldDefaultOptions = {
//   appearance: 'outline',
// };

@NgModule({
  imports: AllMaterialModules,
  exports: AllMaterialModules,
  providers: [
    // {
    //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    //   useValue: appearanceOptions,
    // },
  ]
})
export class MaterialUxModule { }
