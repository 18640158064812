import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { UserAuthService } from '@mg-services/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private userAuthService: UserAuthService
  ) { }

  // ----------------------------------------------------------------------------------------------------------------
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const tIsAdmin: boolean = await this.userAuthService.isAdmin();

    if (!tIsAdmin) {
      const tree: UrlTree = this.router.parseUrl('error');
      return tree;
    }

    return tIsAdmin;


  }

}
